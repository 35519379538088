class FullscreenSearch {
	constructor(trigger) {
		this.trigger = trigger;
		this.isShown = false;
		this.target = document.querySelector(this.trigger.getAttribute("href"));
		this.input = this.target.querySelector(".fullscreen-search__input");
		this.closer = this.target.querySelector(".fullscreen-search__close");

		this.trigger.addEventListener("click", (event) => {
			event.preventDefault();
			this.target.setAttribute("data-is-shown", "true");
			this.isShown = true;
			this.input.focus();
		});

		this.closer.addEventListener("click", (event) => {
			event.preventDefault();
			this.target.setAttribute("data-is-shown", "false");
			this.input.blur();
			this.isShown = false;
		});

		document.body.addEventListener("keydown", (event) => {
			if (this.isShown && event.keyCode === 9) {
				if (!this.target.contains(document.activeElement)) {
					event.preventDefault();
					this.input.focus();
				}
			}

			if (
				this.isShown &&
				(event.keyCode === 27 ||
					(event.altKey === true && event.keyCode === 111))
			) {
				event.preventDefault();
				event.stopPropagation();
				this.input.blur();
				this.target.setAttribute("data-is-shown", "false");
				this.isShown = false;
			}

			if (!this.isShown && event.altKey === true && event.keyCode === 111) {
				event.preventDefault();
				this.target.setAttribute("data-is-shown", "true");
				this.input.focus();
				this.isShown = true;
			}
		});
	}
}
